import Cookies from "js-cookie";
import { ApolloLink, concat, split } from "@apollo/client";
import { registerApolloClient, ApolloClient, InMemoryCache } from '@apollo/experimental-nextjs-app-support';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { Socket as PhoenixSocket, LongPoll } from "phoenix";
import { createClient as createWSClient } from "graphql-ws";
import { createUploadLink } from "apollo-link-upload";
import { getMainDefinition } from "@apollo/client/utilities";

const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV ?? "production";
const backend = process.env.NEXT_PUBLIC_BACKEND;
const cacheFetchPolicy = "no-cache";
const HTTP_URI = backend === "cutting" ? "https://cutting.bunches.io/api" : "https://merlot.bunches.io/api";
const USER_WS_URI = "wss://merlot.bunches.io/v3/rioja";

const apolloCache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          fetchExternalProfile: {
            keyArgs: ['term'],
            merge(existing, incoming) { return incoming; },
          },
        },
      },
    },
  })

const httpLink = createUploadLink({ uri: HTTP_URI });

const wsLink =
  typeof window !== "undefined"
    ? new GraphQLWsLink(
        createWSClient({
          url: USER_WS_URI,
          connectionParams: () => {
            const token = Cookies.get("token");

            if (!token) return {};

            return { token };
          },
        }),
      )
    : null;

const splitLink =
  typeof window !== "undefined" && wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        wsLink,
        httpLink,
      )
    : httpLink;

function createPhoenixSocket() {
  if (typeof window === "undefined") {
    return;
  }

  // Socket with full fallback to LongPoll
  // via https://elixirforum.com/t/fall-back-to-longpoll-when-websocket-fails/23894
  const socket = new PhoenixSocket(USER_WS_URI, {
    params: () => {
      if (Cookies.get("token")) {
        return { token: Cookies.get("token") };
      } else {
        return {};
      }
    },
  });
  socket.onError(() => {
    if (navigator.onLine) {
      // @ts-ignore socket.transport
      const isWebSocket = socket.transport === window.WebSocket;
      console.error(`Error connecting using ${isWebSocket ? "WebSocket" : "LongPoll"}`);
      // @ts-ignore socket.transport
      if (isWebSocket) socket.transport = LongPoll;
      // @ts-ignore socket.transport
      else if (window.WebSocket) socket.transport = window.WebSocket;
    }
  });
  return socket;
}

export const createClient = () => {
  const setupCache = async () => {
    if (typeof window !== 'undefined') {
    await persistCache({
      cache: apolloCache,
      storage: new LocalStorageWrapper(window.localStorage)
    });
  }}

  setupCache();

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: any }) => {
      const token = Cookies.get("token");
      return {
        headers: {
          ...headers,
          "apollographql-client-name": "concord",
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    connectToDevTools: env !== "production",
    link: concat(authMiddleware, splitLink),
    cache: apolloCache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: cacheFetchPolicy,
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: cacheFetchPolicy,
        errorPolicy: "all",
      },
    },
  });

  return {
    client: apolloClient,
    phoenixSocket: createPhoenixSocket(),
  };
};
