import { useCallback, useState } from "react";
import { getAddress } from "viem";
import { mnemonicToAccount } from "viem/accounts";
import { createGenericContext } from "../../utilities/context";
import { getWallet } from "../../utilities/web3/wallet";
import { useAuthContext } from "./AuthProvider";
import { handleCaughtError } from "../../utilities";
import { ModalDetailsFragment, TransactionDetailsFragment, TransactionRequestDetailsFragment } from "../../gql/types";

type UseTransactions = {
  modal?: ModalDetailsFragment;
  requestSignature: (data: TransactionDetailsFragment) => void;
  txDialogOpen: boolean;
  signRequest: (transactionId: string) => Promise<void>;
  close: () => void;
};

const [useTransactionsContext, TransactionsContextProvider] = createGenericContext<UseTransactions>();

const useTransactions = (): UseTransactions => {
  const { currentUser } = useAuthContext();

  const [txDialogOpen, setTxDialogIsOpen] = useState(false);
  const [transactionRequests, setTransactionRequests] = useState<Record<string, TransactionRequestDetailsFragment>>();
  const [modal, setModal] = useState<ModalDetailsFragment>();

  const getAccountSigner = useCallback(async () => {
    try {
      if (!currentUser) return;
      const wallet = await getWallet(currentUser?.userId);
      if (!wallet) throw new Error("Couldn't find wallet address");
      return mnemonicToAccount(wallet.mnemonic);
    } catch (err) {
      handleCaughtError(err);
    }
  }, [currentUser]);

  const requestSignature = useCallback(
    (data: TransactionDetailsFragment) => {
      setTransactionRequests({ ...transactionRequests, [data.transactionId]: data.transactionRequest });
      setModal(data.modal);
      setTxDialogIsOpen(true);
    },
    [transactionRequests],
  );

  const signRequest = useCallback(
    async (transactionId: string) => {
      if (!currentUser || !transactionRequests) return;
      try {
        const transactionRequest = transactionRequests[transactionId];
        if (!transactionRequest) throw new Error("Couldn't find that transaction");

        const account = await getAccountSigner();
        const hash = await account?.signTypedData({
          types: transactionRequest.types,
          domain: {
            ...transactionRequest.domain,
            verifyingContract: getAddress(transactionRequest.domain.verifyingContract),
            chainId: Number(transactionRequest.domain.chainId),
          },
          message: transactionRequest.request,
          primaryType: Object.keys(transactionRequest.types)[0],
        });
        if (!hash) throw new Error("Couldn't sign that request");
        console.log({ hash });
        const { [transactionId]: _, ...updatedTransactionRequests } = transactionRequests;
        setTransactionRequests(updatedTransactionRequests);
      } catch (err) {
        handleCaughtError(err);
      }
    },
    [currentUser, transactionRequests, getAccountSigner],
  );

  const close = useCallback(() => {
    setTxDialogIsOpen(false);
  }, []);

  return {
    modal,
    requestSignature,
    signRequest,
    txDialogOpen,
    close,
  };
};

interface Props {
  children: JSX.Element;
}

const TransactionsProvider = ({ children }: Props) => {
  const tx = useTransactions();

  return <TransactionsContextProvider value={tx}>{children}</TransactionsContextProvider>;
};

export { TransactionsProvider, useTransactionsContext };
