"use client";

import { NormalizedCacheObject } from "@apollo/client";
import { ApolloNextAppProvider, ApolloClient } from "@apollo/experimental-nextjs-app-support";
import { Channel, Socket } from "phoenix";
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import { createGenericContext } from "../../utilities/context";
import { createClient } from "../../utilities/trellis/apolloClient";

type UsePhoenix = {
  trellisSocket: Socket | undefined;
  setTrellisSocket: Dispatch<SetStateAction<Socket | undefined>>;
  client: ApolloClient<NormalizedCacheObject> | undefined;
  setClient: Dispatch<SetStateAction<ApolloClient<NormalizedCacheObject> | undefined>>;
  setChannelName: Dispatch<SetStateAction<string | null>>;
  channel: Channel | null;
};

const [usePhoenixContext, PhoenixContextProvider] = createGenericContext<UsePhoenix>();

const usePhoenix = (): UsePhoenix => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>();
  const [trellisSocket, setTrellisSocket] = useState<Socket | undefined>();
  const [channel, setChannel] = useState<Channel | null>(null);
  const [channelName, setChannelName] = useState<string | null>(null);

  const initApollo = useCallback(() => {
    const apolloClient = createClient();
    setClient(apolloClient.client);
    setTrellisSocket(apolloClient.phoenixSocket);
  }, [setClient]);

  // Initialize apollo client
  useEffect(() => {
    initApollo();
  }, [initApollo]);

  useEffect(() => {
    if (!trellisSocket || !channelName) return;
    const phoenixChannel = trellisSocket.channel(channelName);
    phoenixChannel.join().receive("ok", () => {
      setChannel(phoenixChannel);
    });

    return () => {
      phoenixChannel.leave();
    };
  }, [channelName, trellisSocket]);

  return {
    client,
    setClient,
    trellisSocket,
    setTrellisSocket,
    setChannelName,
    channel,
  };
};

interface Props {
  children: ReactNode;
}

const PhoenixProvider = ({ children }: Props) => {
  const phoenix = usePhoenix();

  if (!phoenix.client) return null;

  return (
    <PhoenixContextProvider value={phoenix}>
      <ApolloNextAppProvider makeClient={() => {return phoenix.client}}>{children}</ApolloNextAppProvider>
    </PhoenixContextProvider>
  );
};

export { usePhoenixContext, PhoenixProvider };
